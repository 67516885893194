import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { fetchPostalCodes } from '../../redux/Actions';
import { setValue } from '../../utility';
import { fetchGetShopByPinCode } from '../../redux/Actions/checkoutPageActions';
import DownloadApp from '../../components/downloadApp'; 
import {
    cleancloths, vehicle, priorityicon1, priorityicon2, priorityicon3, priorityicon4, priorityicon5, priorityicon6,priorityicon7,priorityicon8,priorityicon9,priorityicon10,priorityicon11,priorityicon12,priorityicon13,priorityicon14,priorityicon15,priorityicon16,priorityicon17, priorityimg1, priorityimg2, priorityimg3, appdownload, mobicon1, mobicon2, mobicon3, appstore, googleplay, qrcode, laundrybag1, wing1, wing2, dicon1, dicon2, dicon3, dicon4, dicon5, dicon6, testimonialuser1, testimonialuser2, testimonialuser3, testimonialimg1, testimonialimg2, testimonialimg3, faqimg, trustimg,trustimg2,trustimg3,trustimg4,heroimg,howwe1,howwe2,howwe3,howwe4,cateimg1,cateimg2,cateimg3,cateimg4, userimage
} from '../../assets/img'
import { Accordion } from "react-bootstrap";
import { fetchFaqData } from "../../redux/Actions/policyActions";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import axios from 'axios';
// import BookNow from './index';

// const postalCode = [
//     { content: "GU111BH" },
//     { content: "GU111KD" },
//     { content: "GU111MH" },
// ];

function BookNow() {
    const [postalCodeList, setPostalCodeList] = useState([])
    const [postalCodeValue, setPostalCodeValue] = useState("")
    const dispatch = useDispatch<any>();
    const state: any = useSelector(state => state);
    const navigate = useNavigate();

    const base_url = process.env.REACT_APP_BACKEND_URL;

    // const base_url = "https://revamp.dreamguystech.com"


    // const fetchData = async () => {
    //     const base_url = "https://revamp.dreamguystech.com";
    //     try {
    //         const bodyFormData = new FormData();
    //         bodyFormData.append('keyword', 'Gu11');
    //         // res.header('Access-Control-Allow-Methods', 'GET, POST');
    //         const response = await axios({
    //             method: "post",
    //             url: `${base_url}/api/get_postal_codes`,
    //             data: bodyFormData,
    //             headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST' },
    //         })
    //         // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
    //         // const result = await response.then(response => response);

    //         if (response) {
    //             setPostalCodeList(response.data.data[0])
    //             
    //         }
    //     } catch (error) {
    //         
    //     }
    // }

    const settingsData: any = useSelector<any>(
        (state) => state?.settings
    );

    const [productList, setProductList] = useState<any | null>({})
    const [blogList, setBlogList] = useState<any | null>({})
    useEffect(() => {

        const headers = {
            'Accept': 'application/json',
            "Access-Control-Allow-Origin": "*"
        }

        axios.get(`${base_url}/api/get_categories?group_id=1`, {
            headers: headers

        }).then(e => {
            setProductList({ data: e.data })
            // e.data.data.category.slice(0, 1).map((category: any) =>
            //     setActive(category.main_category_name)
            // )
        })

        axios.get(`${base_url}/api/blog_list?`, {
            headers: headers

        }).then(e => {
            setBlogList(e.data.data)
            // e.data.data.category.slice(0, 1).map((category: any) =>
            //     setActive(category.main_category_name)
            // )
        })
    }, [])

    const faqData: any = useSelector<any>((state) => state?.faq);
    useEffect(() => {
        dispatch(fetchFaqData());
    }, []);

    useEffect(() => {
        if (state) {
            setPostalCodeList(state.postalCodeList);
            // setValue("postalCode",state.postalCodeList[0]?.postcode)
        }

    }, [state])

    const Url = window.location.host
    const blogUrl = `https://blog.${Url}`

    // useEffect(() => {
    //     // fetchData();
    //     dispatch(fetchPostalCodes())
    //     // dispatch(fetchPostalCodes());
    // }, [])

    const handleChange = (e: any) => {
        const result = e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
        // setPostalCodeList(e.target.value);
        const { value } = e.target;
        const updateValue = value.replace(/\s/g, '')
        if (value.length > 0) {
            dispatch(fetchPostalCodes(updateValue));
        }
        setPostalCodeValue(updateValue);

    }
    const onSearch = (searchTerm: any) => {
        // setPostalCodeList(searchTerm);
        setPostalCodeValue(searchTerm);
        setValue("postalCode", searchTerm)
        // dispatch(fetchPostalCodes(searchTerm));


    };
    const handleBooknow = async () => {
        const isValid = state.postalCodeList.some((item: any) => item.postcode === postalCodeValue.toUpperCase());
        setValue("postalCode", postalCodeValue)
        const results = await dispatch(fetchGetShopByPinCode(JSON.stringify(postalCodeValue)));

        if (!results) {
            navigate("/areaNotCovered");
            return;
        }
        if (isValid) {
            navigate("/productLists");
        } else {
            navigate("/areaNotCovered");
        }

    }
    return (
        <>
            <div className="home_single_search">

                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-7">
                            <div className='banner-search-wrap'>
                                <span className='banner-tag'>24/7 Dry Cleaning & Laundry Service</span>
                                <h1>One Solution for all your <span>Washing</span> Needs</h1>
                                <p id='bookNow' className="d-none d-md-block">QuickWash is synonymous with quality, reliability, and trust. We provide fast dry cleaning and laundry services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers.</p>
                                <div className='banner-btn-group'>
                                    <a href='#book-now' className='btn primary-btn hover-btn'><i className='uil uil-package me-2'></i>Request a Pickup</a>
                                    <a href='#book-now' className='btn secondary-btn hover-btn'><i className='uil uil-calendar-alt me-2'></i>Book Now</a>
                                </div>
                                <div className='banner-app'>
                                    <h4>Just A Click Away</h4>
                                    <ul className='banner-app-links'>
                                        {settingsData != null && Object.keys(settingsData).length > 0 && 
                                            settingsData?.WebmasterSettings.app_store_link !== '' &&
                                            <li>
                                                <a target='_blank' href={settingsData?.WebmasterSettings.app_store_link}><img src={appstore} alt="" /></a>
                                            </li>
                                        }
                                        {settingsData != null && Object.keys(settingsData).length > 0 &&
                                            settingsData?.WebmasterSettings.play_store_link !== '' &&
                                            <li>
                                                <a target='_blank' href={settingsData?.WebmasterSettings.play_store_link}><img src={googleplay} alt="" /></a>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 d-none d-lg-block">
                            <div className='heroimg'>
                                <img src={heroimg} alt="" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='homesearch' id='book-now'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className='col-md-9'>
                            <div className='section-title'>
                                <span className='yellow-btn'>Book A Service</span>
                                <h2>Select your time & location for the service</h2>
                            </div>                            
                            <div className="postcodeform">
                                <input type="text" id="postcode-input" value={postalCodeValue} name="pincode" placeholder="Enter Zipcode" className="ui-autocomplete-input" onChange={handleChange} autoComplete="off" />
                                <button disabled={postalCodeValue.length === 0} onClick={handleBooknow} className="btn hover-btn" id="book_now_btn" type="button"><i className='me-2 uil uil-search'></i>Book now</button>
                                <div className="dropdown">
                                    {postalCodeList
                                        .filter((item: any) => {
                                            const searchTerm = postalCodeValue.toLowerCase();
                                            const mainValue = item.postcode.toLowerCase();

                                            return (
                                                searchTerm &&
                                                mainValue.startsWith(searchTerm) &&
                                                mainValue !== searchTerm
                                            );
                                        })
                                        .slice(0, 10)
                                        .map((item: any) => (
                                            <div
                                                onClick={() => onSearch(item.postcode)}
                                                className="dropdown-row"
                                                key={item.postcode}
                                            >
                                                <i className="fas fa-map-marker-alt"></i>{item.postcode}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
            <section className='priority-section'>
                <div className='section-title'>
                    <span className='yellow-btn'>Dry Cleaning & Laundry </span>
                    <h2>trust the Expert on Laundry Services</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has<br/> been the industry's standard dummy text ever since the 1500s,</p>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className='col-md-4'>
                            <div className='priority-card text-center'>
                                <span className='priority-card-icon'><img src={priorityicon1} /></span>
                                <h3>Top Quality</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing </p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='priority-card text-center'>
                                <span className='priority-card-icon'><img src={priorityicon2} /></span>
                                <h3>Best Service</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing </p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='priority-card text-center'>
                                <span className='priority-card-icon'><img src={priorityicon3} /></span>
                                <h3>Value</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing </p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='priority-card text-center'>
                                <span className='priority-card-icon'><img src={priorityicon4} /></span>
                                <h3>Professionalism</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing </p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='priority-card text-center'>
                                <span className='priority-card-icon'><img src={priorityicon5} /></span>
                                <h3>Convenience</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing </p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='priority-card text-center'>
                                <span className='priority-card-icon'><img src={priorityicon6} /></span>
                                <h3>Cleanliness</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard, Lorem Ipsum is simply dummy text of the printing </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className='col-md-12'>
                            <div className='text-center load-more'>
                                <a onClick={() => navigate('/aboutUs')}  className='btn title-btn hover-btn'>More About QuickWash<i className='uil uil-arrow-circle-right ms-2'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='review-section'>
                <div className="container">
                    <div className="row">
                        <div className='col-md-7'>
                            <h2>QuickWash is <span>Ranked #1</span> by customers in the UK in categories of dry cleaning</h2>
                        </div>
                        <div className='col-md-5'>
                            <div className='review-info'>
                                <div className='review-star'>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <p><a className='text-white' href='#' onClick={(e)=> {e.preventDefault(); navigate('reviews');}}>Read more Reviews</a></p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='trust-section'>
                <div className="container">
                    <div className="row">                        
                        <div className='col-md-6'>
                            <div className='split-img'>
                                <img src={trustimg} alt="" />
                                <div className='split-img-framegrid'>                                    
                                    <div className='split-img-frame1'></div>
                                    <div className='split-img-frame2'></div>
                                    <div className='split-img-frame3'></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='trust-content'>
                                <h2>We collect, clean, & deliver your laundry & dry cleaning.</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took </p>
                                <ul>
                                    <li>Free same day collection</li>
                                    <li>24h turnaround time</li>
                                    <li>Dedicated 24/7 support</li>
                                    <li>Standard wash for just £14</li>
                                    <li>Wash & Fold for items that don't need pressing</li>
                                    <li>Free collection and delivery</li>
                                </ul>
                                <div className='trust-content-btn'>
                                    <a href='#book-now' className='btn primary-btn hover-btn'>Request a Pickup<i className='uil uil-arrow-circle-right ms-2'></i></a>
                                    <a href='#book-now' className='btn secondary-btn hover-btn'>Schedule a Collection Now<i className='uil uil-arrow-circle-right ms-2'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='howwe-section' id='how-work-section'>
                <div className="container">
                    <div className="row">                        
                        <div className='col-md-12'>
                            <div className='section-title'>
                                <span className='yellow-btn'>Know More On</span>
                                <h2>How we Works</h2>
                                <p>We collect, clean and deliver your clothes within 24-48 hrs.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">                        
                        <div className='col-md-12'>
                            <Swiper
                                spaceBetween={15}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                    },
                                }}
                                className="home-slider"
                            >
                                <SwiperSlide>
                                    <div className='card-howwe'>
                                        <div className='card-howwe-img'>
                                            <img src={howwe1} alt=""/>
                                            <span>1</span>
                                        </div>
                                        <h3>Online Order</h3>
                                        <p>Use our friendly and easy access website or app to place your laundry order. choose a suitable pickup location and time.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-howwe'>
                                        <div className='card-howwe-img'>
                                            <img src={howwe2} alt=""/>
                                            <span>2</span>
                                        </div>
                                        <h3>Transport</h3>
                                        <p>Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-howwe'>
                                        <div className='card-howwe-img'>
                                            <img src={howwe3} alt=""/>
                                            <span>3</span>
                                        </div>
                                        <h3>Dry Cleaning</h3>
                                        <p>Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-howwe'>
                                        <div className='card-howwe-img'>
                                            <img src={howwe4} alt=""/>
                                            <span>4</span>
                                        </div>
                                        <h3>Door Delivery</h3>
                                        <p>Dry and clean clothes were carefully bagged and delivered to your doorstep.</p>
                                    </div>
                                </SwiperSlide>
                                {/* <SwiperSlide>
                                    <div className='card-howwe'>
                                        <div className='card-howwe-img'>
                                            <img src={howwe1} alt=""/>
                                            <span>1</span>
                                        </div>
                                        <h3>Online Order</h3>
                                        <p>Use our friendly and easy access website or app to place your laundry order. choose a suitable pickup location and time.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-howwe'>
                                        <div className='card-howwe-img'>
                                            <img src={howwe2} alt=""/>
                                            <span>2</span>
                                        </div>
                                        <h3>Transport</h3>
                                        <p>Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-howwe'>
                                        <div className='card-howwe-img'>
                                            <img src={howwe3} alt=""/>
                                            <span>3</span>
                                        </div>
                                        <h3>Dry Cleaning</h3>
                                        <p>Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-howwe'>
                                        <div className='card-howwe-img'>
                                            <img src={howwe4} alt=""/>
                                            <span>4</span>
                                        </div>
                                        <h3>Door Delivery</h3>
                                        <p>Dry and clean clothes were carefully bagged and delivered to your doorstep.</p>
                                    </div>
                                </SwiperSlide> */}
                            </Swiper>
                            
                        </div>
                    </div>
                    <div className="row">                        
                        <div className='col-md-12'>
                            <div className='load-more'>
                                <p>Lorem Ipsum is simply dummy text of the</p>
                                <a href='#book-now' className="btn primary-btn ms-2 hover-btn">Book Online Now<i className="uil uil-arrow-circle-right ms-2"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='cate-section'>
                <div className="container">
                    <div className="row">                        
                        <div className='col-md-12'>
                            <div className='section-title text-start'>
                                <div className="row align-items-center">                        
                                    <div className='col-md-4'>
                                        <h2>On Demand Laundry, <span className='primary-text'>Dry Cleaning And Ironing</span> Categories</h2>
                                    </div>
                                    <div className='col-md'>
                                        <p>We provide commercial laundry, shoe repair, clothing alteration, tailoring and wedding dress fitting services in London, Essex, Kent, Westminster, Chelsea, Dartford, Croydon, Enfield, Basildon, South On Sea, Bromley, Mayfair, Romford and other areas in London.</p>
                                    </div>
                                    <div className='col-md-auto'>
                                        <a href='#book-now' className='btn primary-btn hover-btn'><i className='uil uil-package me-2'></i> Request a Pickup</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">                        
                        <div className='col-md-12'>
                            <Swiper
                                spaceBetween={15}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                    },
                                }}
                                className="home-slider"
                            >
                                {Object.keys(productList).length > 0 && productList?.data?.data?.category?.map((item: any) => {
                                    return <SwiperSlide>
                                        <div className='card-cate'>
                                            <img src={item.web_banner_img} alt=""/>
                                            <div className='card-cate-body'>
                                                <p className='secondary-text'>{item.sub_categories.length} Items</p>
                                                <h3>{item.main_category_name}</h3>
                                                <a href='#' onClick={(e)=>{e.preventDefault(); navigate('pricing');}} className='btn primary-btn rounded-circle hover-btn'><i className='uil uil-arrow-right'></i></a>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                })}
                                
                                {/* <SwiperSlide>
                                    <div className='card-cate'>
                                        <img src={cateimg2} alt=""/>
                                        <div className='card-cate-body'>
                                            <p className='secondary-text'>24 Items</p>
                                            <h3>Knitwear</h3>
                                            <a className='btn primary-btn rounded-circle hover-btn'><i className='uil uil-arrow-right'></i></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-cate'>
                                        <img src={cateimg3} alt=""/>
                                        <div className='card-cate-body'>
                                            <p className='secondary-text'>78 Items</p>
                                            <h3>Sarees</h3>
                                            <a className='btn primary-btn rounded-circle hover-btn'><i className='uil uil-arrow-right'></i></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-cate'>
                                        <img src={cateimg4} alt=""/>
                                        <div className='card-cate-body'>
                                            <p className='secondary-text'>42 Items</p>
                                            <h3>Shirts</h3>
                                            <a className='btn primary-btn rounded-circle hover-btn'><i className='uil uil-arrow-right'></i></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-cate'>
                                        <img src={cateimg1} alt=""/>
                                        <div className='card-cate-body'>
                                            <p className='secondary-text'>16 Items</p>
                                            <h3>Laundry</h3>
                                            <a className='btn primary-btn rounded-circle hover-btn'><i className='uil uil-arrow-right'></i></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-cate'>
                                        <img src={cateimg2} alt=""/>
                                        <div className='card-cate-body'>
                                            <p className='secondary-text'>24 Items</p>
                                            <h3>Knitwear</h3>
                                            <a className='btn primary-btn rounded-circle hover-btn'><i className='uil uil-arrow-right'></i></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-cate'>
                                        <img src={cateimg3} alt=""/>
                                        <div className='card-cate-body'>
                                            <p className='secondary-text'>78 Items</p>
                                            <h3>Sarees</h3>
                                            <a className='btn primary-btn rounded-circle hover-btn'><i className='uil uil-arrow-right'></i></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-cate'>
                                        <img src={cateimg4} alt=""/>
                                        <div className='card-cate-body'>
                                            <p className='secondary-text'>42 Items</p>
                                            <h3>Shirts</h3>
                                            <a className='btn primary-btn rounded-circle hover-btn'><i className='uil uil-arrow-right'></i></a>
                                        </div>
                                    </div>
                                </SwiperSlide> */}
                            </Swiper>                            
                        </div>
                    </div>
                </div>
            </section>
            <section className='trust-section'>
                <div className="container">
                    <div className="row">                        
                        <div className='col-md-6'>
                            <div className='trust-content'>
                                <h2>About QuickWash</h2>
                                <p>QuickWash is synonymous with quality, reliability, and trust. We provide fast dry cleaning and laundry services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers.</p>
                                <ul className='trust-content-media'>
                                    <li>
                                        <div className='d-flex'>
                                            <span className="flex-shrink-0 icon-box">
                                                <img src={priorityicon7} alt =""/>
                                            </span>
                                            <div className="flex-grow-1">
                                                <h4>Free Pick up and Delivery</h4>
                                                <p>We offer a convenient way to have laundry picked up and delivered for free.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='d-flex'>
                                            <span className="flex-shrink-0 icon-box">
                                                <img src={priorityicon8} alt ="" />
                                            </span>
                                            <div className="flex-grow-1">
                                                <h4>High Quality of Services</h4>
                                                <p>Our laundry team perfectly cleans, iron, fold, pack, and deliver to you on time.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='d-flex'>
                                            <span className="flex-shrink-0 icon-box">
                                                <img src={priorityicon9} alt ="" />
                                            </span>
                                            <div className="flex-grow-1">
                                                <h4>Affordable Cost</h4>
                                                <p>We offer affordable prices when compared to the current market.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className='trust-content-btn'>
                                    <a href='#book-now' className='btn primary-btn hover-btn'>Request a Pickup<i className='uil uil-arrow-circle-right ms-2'></i></a>
                                    <a href='#book-now' className='btn secondary-btn hover-btn'>Schedule a Collection Now<i className='uil uil-arrow-circle-right ms-2'></i></a>                                    
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='split-img'>
                                <img src={trustimg2} alt="" />
                                <div className='split-img-framegrid'>                                    
                                    <div className='split-img-frame1'></div>
                                    <div className='split-img-frame2'></div>
                                    <div className='split-img-frame3'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='serv-section' id='service-section'>
                <div className="container">
                    <div className="row">                        
                        <div className='col-md-12'>
                            <div className='section-title'>
                                <span className='yellow-btn'>Our Promising Services</span>
                                <h2 className='text-white'>On Demand Laundry, Dry Cleaning And Ironing Service</h2>
                                <p className='text-white'>We collect, clean and deliver your clothes within 24-48 hrs.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">                        
                        <div className='col-md-12'>
                            <div className='serv-slider-grid'>
                                <Swiper
                                spaceBetween={15}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                    },
                                }}
                                className="home-slider"
                                >
                                    <SwiperSlide>
                                        <div className='card-serv'>
                                            <div className='card-serv-img'>
                                                <img src={priorityimg1} alt=""/>
                                            </div>
                                            <div className='card-serv-body'>
                                                <h3>Dry Cleaning</h3>
                                                <p>A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.</p>
                                                {/* <a className='btn primary-btn rounded-circle hover-btn'><i className='uil uil-arrow-right'></i></a> */}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='card-serv'>
                                            <div className='card-serv-img'>
                                                <img src={priorityimg2} alt=""/>
                                            </div>
                                            <div className='card-serv-body'>
                                                <h3>Alteration & Repair</h3>
                                                <p>A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.</p>
                                                {/* <a className='btn primary-btn rounded-circle hover-btn'><i className='uil uil-arrow-right'></i></a> */}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='card-serv'>
                                            <div className='card-serv-img'>
                                                <img src={priorityimg3} alt=""/>
                                            </div>
                                            <div className='card-serv-body'>
                                                <h3>Laundry</h3>
                                                <p>A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.</p>
                                                {/* <a className='btn primary-btn rounded-circle hover-btn'><i className='uil uil-arrow-right'></i></a> */}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='card-serv'>
                                            <div className='card-serv-img'>
                                                <img src={priorityimg1} alt=""/>
                                            </div>
                                            <div className='card-serv-body'>
                                                <h3>Dry Cleaning</h3>
                                                <p>A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.</p>
                                                {/* <a className='btn primary-btn rounded-circle hover-btn'><i className='uil uil-arrow-right'></i></a> */}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='card-serv'>
                                            <div className='card-serv-img'>
                                                <img src={priorityimg2} alt=""/>
                                            </div>
                                            <div className='card-serv-body'>
                                                <h3>Alteration & Repair</h3>
                                                <p>A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.</p>
                                                {/* <a className='btn primary-btn rounded-circle hover-btn'><i className='uil uil-arrow-right'></i></a> */}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='card-serv'>
                                            <div className='card-serv-img'>
                                                <img src={priorityimg3} alt=""/>
                                            </div>
                                            <div className='card-serv-body'>
                                                <h3>Laundry</h3>
                                                <p>A tender cleaning procedure is done which may include fluff drying process depending on the fabric. All clothes are pressed, and hand finished. Our methods enhance colours and refresh the textiles of the fabrics.</p>
                                                {/* <a className='btn primary-btn rounded-circle hover-btn'><i className='uil uil-arrow-right'></i></a> */}
                                            </div>
                                        </div>
                                    </SwiperSlide>                                
                                </Swiper>                                
                            </div>
                        </div>
                    </div>
                    <div className="row">                        
                        <div className='col-md-12'>
                            <ul className='appdata'>
                                <li>
                                    <div className="media d-flex">
                                        <span className="flex-shrink-0">
                                            <img src={priorityicon10} alt="" />
                                        </span>
                                        <div className="flex-grow-1">
                                            <h4>1639+</h4>
                                            <p>Laundry Clothes</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="media d-flex">
                                        <span className="flex-shrink-0">
                                            <img src={priorityicon11} alt="" />
                                        </span>
                                        <div className="flex-grow-1">
                                            <h4>24359+</h4>
                                            <p>Steam Ironing</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="media d-flex">
                                        <span className="flex-shrink-0">
                                            <img src={priorityicon12} alt="" />
                                        </span>
                                        <div className="flex-grow-1">
                                            <h4>4793+</h4>
                                            <p>Dry Cleaning</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="media d-flex">
                                        <span className="flex-shrink-0">
                                            <img src={priorityicon13} alt="" />
                                        </span>
                                        <div className="flex-grow-1">
                                            <h4>25+</h4>
                                            <p>Cleaning programs </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>                  
                        </div>
                    </div>
                </div>
            </section>
            <section className='safety-section'>
                <div className="container">
                    <div className="row">                        
                        <div className='col-md-12'>
                            <div className='section-title'>
                                <span className='yellow-btn'>Our Values</span>
                                <h2>Your Health & Safety Is Our Priority</h2>
                                <p>online laundry & dry cleaning companies who simply just own the technology and farm the<br/> cleaning to other providers with little to no process system checks.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-6'>
                            <div className='split-img'>
                                <img src={trustimg3} alt="" />
                            </div>
                        </div>                        
                        <div className='col-md-6'>
                            <div className='safety-content'>
                                <ul>
                                    <li>                                        
                                        <h3>Contactless deliveries</h3>
                                        <p>For our customers safety, we enabled the feature to allow customers to conduct contactless pick up & deliveries.</p>
                                    </li>
                                    <li>                                        
                                        <h3>Instant updates</h3>
                                        <p>We’ll keep you posted throughout the process with texts/emails when driver is on their way and order has been cleaned.</p>
                                    </li>
                                    <li>                                        
                                        <h3>Monitoring the situation</h3>
                                        <p>We’re keeping up to date with all developments from government to ensure the safety of our customers and staff.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className='col-md-3'>
                            <h2>We use Eco-friendly Laundry <span className='primary-text'>Detergent Sheets.</span></h2>                            
                        </div>
                        <div className='col-md-3'>
                            <div className='safety-list-card safety-list-card-bg1'>
                                <h3>Vegan</h3>
                                <p>Our vegan-friendly laundry detergent is free from any animal product.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='safety-list-card safety-list-card-bg2'>
                                <h3>Cruelty Free</h3>
                                <p>We never test on animals – so our Laundry Leaves will always be cruelty-free.</p>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='safety-list-card safety-list-card-bg3'>
                                <h3>Eco-Friendly</h3>
                                <p>Free from any harmful chemicals or pollutants and are devoid of harmful toxins.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>            
            <section className='faq-section'>
                <div className="container">
                    <div className="row">                        
                        <div className='col-md-12'>
                            <div className='section-title'>
                                <span className='yellow-btn'>Ask Your Question</span>
                                <h2>Frequently Asked Questions</h2>
                                <p>online laundry & dry cleaning companies who simply just own the technology and farm the<br/> cleaning to other providers with little to no process system checks.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-6'>
                            <div className='split-img'>
                                <img src={trustimg4} alt="" />
                                <div className='split-img-framegrid'>                                    
                                    <div className='split-img-frame1'></div>
                                    <div className='split-img-frame2'></div>
                                    <div className='split-img-frame3'></div>
                                </div>
                            </div>
                        </div>                        
                        <div className='col-md-6'>                            
                            <div className='faq-content'>
                                <Accordion className="faq-home">
                                {faqData?.slice(0,6).map((item: any) => {
                                    return (
                                        <Accordion.Item eventKey={JSON.stringify(item?.id)}>
                                            <Accordion.Header>{item.question}</Accordion.Header>
                                            <Accordion.Body>{item.answer}</Accordion.Body>
                                        </Accordion.Item>
                                    )})}                                    
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>            
            <section className='testimonial-section'>
                <div className="container">
                    <div className="row">                        
                        <div className='col-md-12'>
                            <div className='section-title'>
                                <span className='yellow-btn'>What Our Client Says</span>
                                <h2>A Trusted Laundry and Dry Cleaning Company</h2>
                                <p>Read what our customers say about QuickWash</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row justify-content-center">
                            <div className='col-md-4'>
                                <div className='testimonial-card'>
                                    <div className='profile-user'>
                                        <a className='profile-user-img'>
                                            <img src={testimonialuser1} className="rounded-circle" />
                                        </a>
                                        <div className='profile-user-info'>
                                            <h6><a>Ariyan Rusov</a></h6>
                                            <div className='profile-rating'>
                                                <span className='secondary-text'>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                </span>
                                                <span>5.0</span>
                                            </div>
                                        </div>
                                    </div>
                                    <h5>Finibus mauris. Aliquam consectetur</h5>
                                    <p>My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='testimonial-card'>
                                    <div className='profile-user'>
                                        <a className='profile-user-img'>
                                            <img src={testimonialuser2} className="rounded-circle" />
                                        </a>
                                        <div className='profile-user-info'>
                                            <h6><a>Darren Valdez</a></h6>
                                            <div className='profile-rating'>
                                                <span className='secondary-text'>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                </span>
                                                <span>5.0</span>
                                            </div>
                                        </div>
                                    </div>
                                    <h5>Finibus mauris. Aliquam consectetur</h5>
                                    <p>My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='testimonial-card'>
                                    <div className='profile-user'>
                                        <a className='profile-user-img'>
                                            <img src={testimonialuser3} className="rounded-circle" />
                                        </a>
                                        <div className='profile-user-info'>
                                            <h6><a>Elinor Dunn</a></h6>
                                            <div className='profile-rating'>
                                                <span className='secondary-text'>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                </span>
                                                <span>5.0</span>
                                            </div>
                                        </div>
                                    </div>
                                    <h5>Finibus mauris. Aliquam consectetur</h5>
                                    <p>My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">                        
                        <div className='col-md-12'>
                            <div className='load-more mt-5'>
                                <p className='d-block mb-4'>More than 5,000+ reviews s on <b>TrustPilot, Google, Google Play & App Store</b></p>
                                <a href='#book-now' className="btn primary-btn ms-2 hover-btn">Book Online Now<i className="uil uil-arrow-circle-right ms-2"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mobapp-section'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className='col-md-4'>
                            <div className='mobapp-content'>
                                <div className='section-title text-start'>
                                    <h2>Just a <span className='primary-text'>Click Away</span></h2>
                                    <p>Download Our Best Active Mobile App</p>
                                </div>                                
                                <ul className='app-highlights'>
                                    <li>
                                        <div className='d-flex align-items-center'>
                                            <span className="flex-shrink-0 icon-box">
                                                <img src={priorityicon14} alt="" />
                                            </span>
                                            <div className="flex-grow-1">
                                                <h5>Cashless payments</h5>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='d-flex align-items-center'>
                                            <span className="flex-shrink-0 icon-box">
                                                <img src={priorityicon15} alt="" />
                                            </span>
                                            <div className="flex-grow-1">
                                                <h5>Delivery in less then 24 hours</h5>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='d-flex align-items-center'>
                                            <span className="flex-shrink-0 icon-box">
                                                <img src={priorityicon16} alt="" />
                                            </span>
                                            <div className="flex-grow-1">
                                                <h5>Standard wash for just £14</h5>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='d-flex align-items-center'>
                                            <span className="flex-shrink-0 icon-box">
                                                <img src={priorityicon17} alt="" />
                                            </span>
                                            <div className="flex-grow-1">
                                                <h5>In-app live chat support</h5>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                {/* <div className='qr-grid'>
                                    <div className='d-flex'>
                                        <span className="flex-shrink-0">
                                            <img src={qrcode} alt="" />
                                        </span>
                                        <div className="flex-grow-1 ms-2">
                                            <h6>Scan here</h6>
                                            <div className='profile-rating'>
                                                <span className='secondary-text'>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                    <i className='fas fa-star'></i>
                                                </span>
                                                <span>5.0</span>
                                            </div>
                                            <a href=''>www.bestatlaundry.com</a>
                                        </div>
                                    </div>
                                </div> */}
                                <ul className='applinks'>
                                    {settingsData != null && Object.keys(settingsData).length > 0 && 
                                        settingsData?.WebmasterSettings.app_store_link !== '' &&
                                        <li>
                                            <a target='_blank' href={settingsData?.WebmasterSettings.app_store_link}><img src={appstore} alt="" /></a>
                                        </li>
                                    }

                                    {/* <li>
                                    <a className='qrbox'><img src={qrcode} alt=""/></a>
                                </li> */}
                                    {settingsData != null && Object.keys(settingsData).length > 0 &&
                                        settingsData?.WebmasterSettings.play_store_link !== '' &&
                                        <li>
                                            <a target='_blank' href={settingsData?.WebmasterSettings.play_store_link}><img src={googleplay} alt="" /></a>
                                        </li>
                                    }
                                </ul>
                                
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='mobapp-img text-center'>
                                <img src={appdownload} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <DownloadApp /> 
            <section className='services-section d-none' id='services-section'>
                <div className="container">
                    <div className="row">
                        <div className='col-md-12'>
                            <h2 className='section-title'>On Demand <span>Laundry, Dry Cleaning and Ironing</span> Service</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-12'>
                            <div className='services-slider'>
                                <Swiper
                                    spaceBetween={15}
                                    navigation={true}
                                    modules={[Navigation]}
                                    breakpoints={{
                                        640: {
                                            slidesPerView: 1,
                                        },
                                        768: {
                                            slidesPerView: 4,
                                        },
                                        1024: {
                                            slidesPerView: 6,
                                        },
                                    }}
                                >
                                {Object.keys(productList).length > 0 && productList?.data?.data?.category?.map((item: any)=>{
                                    return <SwiperSlide>
                                        <div className='services-slider-grid'>
                                            <img src={item.web_banner_img} alt="" />
                                            <h5>{item.main_category_name}</h5>
                                            {/* <p>There are many variations of passages of Lorem Ipsum available.</p> */}
                                            <a href='#bookNow' className='btn btnslider hover-btn'>Book Now<i className="fas fa-chevron-right"></i></a>
                                        </div>
                                    </SwiperSlide>
                                })}                                
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='latestnews-section' id='news-section'>
                <div className="container">
                    <div className="row">                        
                        <div className='col-md-12'>
                            <div className='section-title'>
                                <span className='yellow-btn'>Our Latest</span>
                                <h2>News & Events</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus,<br/> luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-12'>
                        <Swiper
                            spaceBetween={15}
                            navigation={true}
                            modules={[Navigation]}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                1024: {
                                    slidesPerView: 3,
                                },
                            }}
							className="home-slider"
                        >
                            {blogList?.length > 0 &&
                                <SwiperSlide>                                
                                    <div className='latestnews-card'>
                                        <div className='latestnews-img'>
                                            <img src={testimonialimg1} alt="" />
                                            <div className='latestnews-info'>
                                                <div className='latestnews-top'>
                                                    <span className='latestnews-tag'>{blogList[0].category_name}</span>
                                                    {/* <span className='latestnews-addfav rounded-circle'><i className='uil uil-favorite'></i></span> */}
                                                </div>
                                                <div className='latestnews-bottom'>
                                                    <div className='profile-user'>
                                                        <a className='profile-user-img'>
                                                            <img src={blogList[0].grid_sm_img}  className="rounded-circle" />
                                                        </a>
                                                        <div className='profile-user-info latestnews-user-info'>
                                                            {/* <h6><a target='_blank' href="">Orlando Waters</a></h6> */}
                                                            <span className='latestnews-date'><i className='uil uil-calendar-alt me-2'></i>{blogList[0].formatted_date} {blogList[0].formatted_month} {blogList[0].formatted_year}</span>
                                                        </div>
                                                    </div>
                                                    <h3><a target='_blank' className='text-white' href={`${blogUrl}/blogg/${blogList[0].id}/${blogList[0].url_slug}`}>{blogList[0].page_title.substring(0,80)}{blogList[0].page_title.length > 80 ? '...' : ''}</a></h3>
                                                    {/* <div className='latestnews-arlk-count'>
                                                        <span className='arlk-count-tag'><i className='uil uil-heart-sign me-2'></i>45</span>
                                                        <span className='arlk-count-tag'><i className='uil uil-comment-alt me-2'></i>40</span>
                                                        <span className='ms-auto'><i className='uil uil-stopwatch me-2'></i>10 Min To Read</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>                                    
                                    </div>
                                </SwiperSlide>
                            }
                            {blogList?.length > 1 &&
                                <SwiperSlide>                                
                                    <div className='latestnews-card'>
                                        <div className='latestnews-img'>
                                            <img src={testimonialimg2} alt="" />
                                            <div className='latestnews-info'>
                                                <div className='latestnews-top'>
                                                    <span className='latestnews-tag'>{blogList[1].category_name}</span>
                                                    {/* <span className='latestnews-addfav rounded-circle'><i className='uil uil-favorite'></i></span> */}
                                                </div>
                                                <div className='latestnews-bottom'>
                                                    <div className='profile-user'>
                                                        <a className='profile-user-img'>
                                                            <img src={blogList[1].grid_sm_img}  className="rounded-circle" />
                                                        </a>
                                                        <div className='profile-user-info latestnews-user-info'>
                                                            {/* <h6><a target='_blank' href="">Richard Carg</a></h6> */}
                                                            <span className='latestnews-date'><i className='uil uil-calendar-alt me-2'></i>{blogList[1].formatted_date} {blogList[1].formatted_month} {blogList[1].formatted_year}</span>
                                                        </div>
                                                    </div>
                                                    <h3><a target='_blank' className='text-white' href={`${blogUrl}/blogg/${blogList[1].id}/${blogList[1].url_slug}`}>{blogList[1].page_title.substring(0,80)}{blogList[1].page_title.length > 80 ? '...' : ''}</a></h3>
                                                    {/* <div className='latestnews-arlk-count'>
                                                        <span className='arlk-count-tag'><i className='uil uil-heart-sign me-2'></i>45</span>
                                                        <span className='arlk-count-tag'><i className='uil uil-comment-alt me-2'></i>40</span>
                                                        <span className='ms-auto'><i className='uil uil-stopwatch me-2'></i>10 Min To Read</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>                                    
                                    </div>
                                </SwiperSlide>
                            }
                            {blogList?.length > 2 &&
                                <SwiperSlide>                                
                                    <div className='latestnews-card'>
                                        <div className='latestnews-img'>
                                            <img src={testimonialimg3} alt="" />
                                            <div className='latestnews-info'>
                                                <div className='latestnews-top'>
                                                    <span className='latestnews-tag'>{blogList[2].category_name}</span>
                                                    {/* <span className='latestnews-addfav rounded-circle'><i className='uil uil-favorite'></i></span> */}
                                                </div>
                                                <div className='latestnews-bottom'>
                                                    <div className='profile-user'>
                                                        <a className='profile-user-img'>
                                                            <img src={blogList[2].grid_sm_img}  className="rounded-circle" />
                                                        </a>
                                                        <div className='profile-user-info latestnews-user-info'>
                                                            {/* <h6><a target='_blank' href="">Andrea Masadoni</a></h6> */}
                                                            <span className='latestnews-date'><i className='uil uil-calendar-alt me-2'></i>{blogList[2].formatted_date} {blogList[2].formatted_month} {blogList[2].formatted_year}</span>
                                                        </div>
                                                    </div>
                                                    <h3><a target='_blank' className='text-white' href={`${blogUrl}/blogg/${blogList[2].id}/${blogList[2].url_slug}`}>{blogList[2].page_title.substring(0,80)}{blogList[2].page_title.length > 80 ? '...' : ''}</a></h3>
                                                    {/* <div className='latestnews-arlk-count'>
                                                        <span className='arlk-count-tag'><i className='uil uil-heart-sign me-2'></i>45</span>
                                                        <span className='arlk-count-tag'><i className='uil uil-comment-alt me-2'></i>40</span>
                                                        <span className='ms-auto'><i className='uil uil-stopwatch me-2'></i>10 Min To Read</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>                                    
                                    </div>
                                </SwiperSlide>
                            }
                        </Swiper>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-12'> 
                            <div className='load-more mt-5'>
                                <a target='_blank' href={blogUrl} className='btn primary-btn hover-btn'>More News & Events<i className='uil uil-arrow-circle-right'></i> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BookNow;